export default [
  {
    path: '/good/list',
    component: () => import('@/views/good/List.vue'),
    meta: {
      title: '商品列表',
    },
  },
  {
    path: '/good/create',
    component: () => import('@/views/good/Edit.vue'),
    meta: {
      title: '创建商品',
    },
  },
  {
    path: '/good/edit/:id',
    component: () => import('@/views/good/Edit.vue'),
    props: true,
    meta: {
      title: '修改商品',
    },
  },

  {
    path: '/wallet/list',
    component: () => import('@/views/wallet/List.vue'),
    meta: {
      title: '钱包列表',
    },
  },
  {
    path: '/wallet/create',
    component: () => import('@/views/wallet/Edit.vue'),
    meta: {
      title: '创建钱包',
    },
  },
  {
    path: '/wallet/edit/:id',
    component: () => import('@/views/wallet/Edit.vue'),
    props: true,
    meta: {
      title: '修改钱包',
    },
  },

  {
    path: '/code/list',
    component: () => import('@/views/code/List.vue'),
    meta: {
      title: '激活码列表',
    },
  },
  {
    path: '/code/create',
    component: () => import('@/views/code/Edit.vue'),
    meta: {
      title: '创建激活码',
    },
  },

  {
    path: '/cart_code/list',
    component: () => import('@/views/cart_code/List.vue'),
    meta: {
      title: '购物车激活码列表',
    },
  },
  {
    path: '/cart_code/create',
    component: () => import('@/views/cart_code/Edit.vue'),
    meta: {
      title: '创建购物车激活码',
    },
  },

  {
    path: '/proxy/list',
    component: () => import('@/views/proxy/List.vue'),
    meta: {
      title: '代理列表',
    },
  },
  {
    path: '/proxy/create',
    component: () => import('@/views/proxy/Edit.vue'),
    meta: {
      title: '创建代理',
    },
  },
  {
    path: '/proxy/edit/:id',
    component: () => import('@/views/proxy/Edit.vue'),
    props: true,
    meta: {
      title: '修改代理',
    },
  },

  {
    path: '/order/list',
    component: () => import('@/views/order/List.vue'),
    meta: {
      title: '订单列表',
    },
  },

  {
    path: '/rate/list',
    component: () => import('@/views/rate/List.vue'),
    meta: {
      title: '汇率列表',
    },
  },
  {
    path: '/rate/create',
    component: () => import('@/views/rate/Edit.vue'),
    meta: {
      title: '创建汇率',
    },
  },
  {
    path: '/rate/edit/:id',
    component: () => import('@/views/rate/Edit.vue'),
    props: true,
    meta: {
      title: '修改汇率',
    },
  },
  {
    path: '/code_service/list',
    component: () => import('@/views/code_service/List.vue'),
    meta: {
      title: '激活码管理(客服)',
    },
  },

  {
    path: '/ar_order/list',
    component: () => import('@/views/ar_order/List.vue'),
    meta: {
      title: '阿根廷订单列表',
    },
  },
  {
    path: '/cart_code_service/list',
    component: () => import('@/views/cart_code_service/List.vue'),
    meta: {
      title: '购物车激活码管理(客服)',
    },
  },
  {
    path: '/cart_order/list',
    component: () => import('@/views/cart_order/List.vue'),
    meta: {
      title: '购物车订单列表',
    },
  },
]
