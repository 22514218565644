<template>
  <el-container style="height: 100%">
    <template v-if="user.role.menus && user.role.menus.length">
      <el-menu
        router
        :default-openeds="[menuIndex]"
        :default-active="$route.path"
        mode="vertical"
        @open="handleMenuOpen"
        class="el-menu-vertical"
        :collapse="isCollapse"
      >
        <template v-for="menu in user.role.menus">
          <el-submenu
            :index="menu.id.toString()"
            :key="menu.id"
            v-if="menu.children && menu.children.length"
          >
            <template slot="title"
              ><i :class="menu.icon"></i
              ><span slot="title">{{ menu.name }}</span></template
            >
            <el-menu-item
              :index="child.url"
              v-for="child in menu.children"
              :key="child.id"
              >{{ child.name }}</el-menu-item
            >
          </el-submenu>
          <el-menu-item v-else :index="menu.url" :key="menu.id"
            ><i :class="menu.icon"></i>
            <span slot="title">{{ menu.name }}</span>
          </el-menu-item>
        </template>
      </el-menu>
    </template>

    <el-container>
      <el-header class="nav-header">
        <div class="nav-header__left">
          <i
            class="el-icon-s-fold toggle-icon"
            :class="isCollapse ? 'active' : ''"
            @click="toggleSidebar"
          ></i>
        </div>
        <div class="nav-header__right space-x-1">
          <i class="el-icon-full-screen" @click="fullscreen"></i>
          <el-dropdown @command="handleCommand">
            <i class="el-icon-setting"></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span>{{ user.username }}</span>
        </div>
      </el-header>

      <div class="tags-view-container">
        <div class="tags-view__list card-shadow">
          <router-link
            v-for="(tag, index) in cachedViews"
            :key="index"
            class="tags-view__item"
            :class="isActive(tag) ? 'tags-view__item--active' : ''"
            :to="tag"
            custom
            v-slot="{ navigate }"
            @contextmenu.prevent.native="openMenu(tag, $event)"
          >
            <span @click="navigate" @keypress.enter="navigate" role="link">
              <span class="tags-view__item__text">{{ tag.meta.title }}</span>
              <span
                class="tags-view__item__close"
                @click.prevent.stop="closeSelectedTag(tag)"
              >
                <i class="el-icon-close"></i>
              </span>
            </span>
          </router-link>
        </div>
        <ul
          v-show="visible"
          :style="{ left: left + 'px', top: top + 'px' }"
          class="contextmenu"
        >
          <li @click="closeSelectedTag(selectedTag)">Close</li>
          <li @click="closeOthersTags">Close Others</li>
          <li @click="closeAllTags(selectedTag)">Close All</li>
        </ul>
      </div>

      <el-main>
        <transition name="fade-transform" mode="out-in">
          <router-view />
        </transition>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import screenfull from 'screenfull'
import { mapGetters } from 'vuex'
import { data2tree } from '../utils/tree'

export default {
  data() {
    return {
      menuIndex: 1,
      isCollapse: false,
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {},
    }
  },
  computed: {
    ...mapGetters(['cachedViews']),
    user() {
      const user = this.$store.getters.user
      if (user.role?.menus && Object.keys(user.role.menus).length) {
        user.role.menus = data2tree(
          user.role.menus.filter((i) => !i.hidden),
          null,
        )
      } else {
        user.role = {}
        user.role.menus = []
      }
      return user
    },
  },
  methods: {
    handleMenuOpen(index) {
      this.menuIndex = index
    },
    async handleCommand(command) {
      switch (command) {
        case 'logout':
          await this.$store.dispatch('logout')
          this.$router.push('/login')
          break
      }
    },
    toggleSidebar() {
      this.isCollapse = !this.isCollapse
    },
    fullscreen() {
      if (!screenfull.isEnabled) {
        this.$message({
          message: 'Your browser does not support!',
          type: 'warning',
        })
        return false
      }
      screenfull.toggle()
    },
    isActive(route) {
      return route.path === this.$route.path
    },
    closeSelectedTag(route) {
      this.$store.dispatch('delView', route).then(() => {
        if (this.isActive(route)) {
          this.toLastView()
        }
      })
    },
    closeOthersTags() {
      this.$store.dispatch('delOthersViews', this.selectedTag)
    },
    toLastView() {
      if (this.cachedViews && this.cachedViews.length) {
        const lastView = this.cachedViews[this.cachedViews.length - 1]
        this.$router.push(lastView.fullPath)
      } else {
        this.$router.push('/')
      }
    },
    openMenu(tag, e) {
      const menuMinWidth = 105
      const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
      const offsetWidth = this.$el.offsetWidth // container width
      const maxLeft = offsetWidth - menuMinWidth // left boundary
      const left = e.clientX - offsetLeft + 15 // 15: margin right
      if (left > maxLeft) {
        this.left = maxLeft
      } else {
        this.left = left
      }
      this.top = e.clientY
      this.visible = true
      this.selectedTag = tag
    },
    closeMenu() {
      this.visible = false
    },
    closeAllTags() {
      this.$store.dispatch('delAllViews').then(() => this.toLastView([]))
    },
  },
  watch: {
    $route() {
      const { meta } = this.$route
      if (meta.title) {
        this.$store.dispatch('addView', this.$route)
      }
      return false
    },
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    },
  },
}
</script>

<style lang="scss">
.tags-view__list {
  display: flex;
  list-style: none;
  padding: 6px 0;
  .tags-view__item {
    position: relative;
    cursor: pointer;
    height: 26px;
    line-height: 26px;
    border: 1px solid #d8dce5;
    color: #495060;
    background: #fff;
    padding: 0 8px 0 12px;
    font-size: 12px;
    margin-left: 5px;
    margin-top: 4px;
    display: flex;
    align-items: center;
    > * {
      flex-shrink: 0;
    }
    &--active {
      background-color: #42b983;
      color: #fff;
      border-color: #42b983;
      &::before {
        content: '';
        background: #fff;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        position: relative;
        margin-right: 4px;
      }
    }
    &__close {
      border-radius: 50%;
      width: 16px;
      height: 16px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transform-origin: 100% 50%;
      margin-left: 0.4em;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: #b4bccc;
        color: #fff;
      }
      .el-icon-close {
        font-size: 12px;
        line-height: 1;
      }
    }
  }
}
.contextmenu {
  margin: 0;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
  li {
    margin: 0;
    padding: 7px 16px;
    cursor: pointer;
    &:hover {
      background: #eee;
    }
  }
}
</style>
