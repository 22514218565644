import axios, { AxiosInstance } from 'axios'
import { Message } from 'element-ui'

import router from '../router'

const instance: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 6000 * 10,
})

/**
 * 用户身份头
 */
instance.interceptors.request.use((config) => {
  if (localStorage.token) {
    config.headers.Authorization = `Bearer ${localStorage.token}`
  }
  return config
})

/**
 * 如果错误结果返回的格式是 { error: 'xxxx' }
 * 直接用 Message 提示错误
 * 401 错误清除 token 跳转到登录
 */
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    let errorMessage = '请求失败'
    if (typeof error.response.data === 'string') {
      errorMessage = error.response.data
    } else if (
      error.response.data?.error &&
      typeof error.response.data.error === 'string'
    ) {
      errorMessage = error.response.data.error
    }
    console.log(errorMessage)
    Message.error(errorMessage)
    if (error.response?.status === 401) {
      localStorage.removeItem('token')
      router.push('/login')
    }
    if (error.response?.status === 403) {
      router.back()
    }
    return Promise.reject(error)
  },
)

export default instance
